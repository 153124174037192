import * as PropTypes from "prop-types";
import React from "react";

function CoursesTableDesktop(props) {
    return <table border="0" cellPadding="0" cellSpacing="3" width="100%">
        <tbody>
        <tr>
            <td className="detailconsolecontent" border="1">
                {(props.state.apiMessage.coursesWithDetails !== undefined && props.state.apiMessage.coursesWithDetails.length > 0 &&
                        <table border="0" width="100%" id={"coursesTable"}>
                            <thead>
                            <tr>
                                <th className="cell celloldlight" align="center">Rds</th>
                                <th className="cell celloldlight" align="center">Full</th>
                                <th nowrap="true" className="cell celloldlight" align="center">-4</th>
                                <th nowrap="true" className="cell celloldlight" align="center">-3</th>
                                <th nowrap="true" className="cell celloldlight" align="center">-2</th>
                                <th nowrap="true" className="cell celloldlight" align="center">-1</th>
                                <th nowrap="true" className="cell celloldlight" align="center">Par</th>
                                <th nowrap="true" className="cell celloldlight" align="center">+1</th>
                                <th nowrap="true" className="cell celloldlight" align="center">+2</th>
                                <th nowrap="true" className="cell celloldlight" align="center">+3</th>
                                <th nowrap="true" className="cell celloldlight" align="center">+4+</th>
                                <th className="cell celloldlight" align="center">Ttl</th>
                            </tr>
                            </thead>
                            <tbody>

                            {Array.from(props.state.apiMessage.coursesWithDetails).map(props.callbackfn)}

                            </tbody>
                        </table>)
                    ||
                    "No courses found for this user"
                }
            </td>
        </tr>
        </tbody>
    </table>;
}

CoursesTableDesktop.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.any,
        error: PropTypes.any,
        apiMessage: PropTypes.shape({
            coursesWithDetails: PropTypes.arrayOf(PropTypes.any),
            accessLevel: PropTypes.string,
            userId: PropTypes.number
        }),
        showResult: PropTypes.bool
    }),
    callbackfn: PropTypes.func
};

export default CoursesTableDesktop;