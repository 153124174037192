import React from "react";
import loading from "../assets/loading.svg";

const ConsoleLoading = () => (
  <div>
    <table width={"100%"} className="text-center">
      <tbody>
        <tr>
          <td className="detailconsolecontent" style={{textAlign: 'center', padding: '20px 0'}}>
            <img src={loading} alt="Loading" style={{width: '50px'}} />
            <div style={{marginTop: '10px', fontSize: '14px', color: '#666'}}>Loading...</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default ConsoleLoading;
