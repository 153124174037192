import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export const CourseRowMobile = ({ courseWithDetails, courseOwnerId, accessLevel }) => {
    const navigate = useNavigate();

    // Extract course and details from the courseWithDetails object
    const { course, details } = courseWithDetails;

     return (
         <>
             <tr>
             <td nowrap="true" className="cell cellheader" colSpan={16}>
                 <button className={"button_as_link"} style={{'textDecoration':'none', color:'#FFFFFF'}}
                         onClick={() => navigate("/view-rounds/?friendId=" + courseOwnerId + "&courseId=" + course.courseId)}>
                     {course.courseName} ({course.courseTeesName})</button>
             </td>

             </tr>

             <tr>
                <td className="cell celloldlight" align="center">{details.rounds}</td>
                <td className="cell celloldlight" align="center">{details.fullRounds}</td>
                {details.underOverCount ? (
                     <>
                <td align="center" className="cell cellm4">{details.underOverCount[0]}</td>
                <td align="center" className="cell cellalbatross">{details.underOverCount[1]}</td>
                <td align="center" className="cell celleagle">{details.underOverCount[2]}</td>
                <td align="center" className="cell cellbirdie">{details.underOverCount[3]}</td>
                <td align="center" className="cell cellpar">{details.underOverCount[4]}</td>
                <td align="center" className="cell cellbogey">{details.underOverCount[5]}</td>
                <td align="center" className="cell celldoublebogey">{details.underOverCount[6]}</td>
                <td align="center" className="cell cell3">{details.underOverCount[7]}</td>
                <td align="center" className="cell cell4">{details.underOverCount[8]}</td>
                </>
                ) : (<td colSpan={9} align="center" className="cell">There was an error loading these details, please reload the page</td>)}
                <td align="center" className="cell celloldlight" bgcolor="#CCFFCC">3</td>
        </tr>
             </>
     )
}

export default CourseRowMobile;
