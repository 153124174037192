import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import CourseRowDesktop from "../components/Courses/CourseRowDesktop";
import CourseRowMobile from "../components/Courses/CourseRowMobile";
import Loading from "../components/Loading";
import ConsoleLoading from "../components/ConsoleLoading";
import ContentHeader from "../components/ContentHeader";
import FriendDrop from "../components/Friends/FriendDrop";
import {useLocation, useNavigate} from "react-router-dom";
import CoursesTableDesktop from "../components/Courses/CoursesTableDesktop";
import CoursesTableMobile from "../components/Courses/CoursesTableMobile";
import { useWindowSize } from "../components/Hooks/useWindowSize"
import pRetry from 'p-retry';
import {checkVersion, retryFetchJson, retryOptions} from "../utils/Net";
import Pagination from "../components/Rounds/Pagination";

export const MyCoursesComponent = () => {
  const { apiOrigin = "http://localhost:8080" } = getConfig();
  const search = useLocation().search;
  const defaultFriendId = new URLSearchParams(search).get('friendId') || 0
  const navigate = useNavigate();
  const [width] = useWindowSize()

  const [state, setState] = useState({
    showResult: false,
    apiMessage: {
      userId: 1,
      accessLevel: "NONE",
      coursesWithDetails: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: undefined,
      totalPages: undefined,
    },
    friendId: defaultFriendId,
    error: null,
    loaded: false,
    isLoading: false,
  });

  const {
    getAccessTokenSilently,
    isLoading,
    error, } = useAuth0();

  useEffect(() => {
    checkVersion();

    setState({...state, isLoading: true});
    getState(defaultFriendId, 1, 25).then(stateData => {
      setState({...stateData, isLoading: false})
    })

    return () => {
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  async function getState(friendId, page, pageSize) {
    try {
      const token = await getAccessTokenSilently();

      let uri = `${apiOrigin}/courses`;
      uri += "?details=true&page=" + page + "&pageSize=" + pageSize;
      if (friendId !== 0) {
        uri += "&friendId=" + friendId;
      }

      const responseData = await pRetry(() => retryFetchJson(uri, token), retryOptions);

      return {
        ...state,
        friendId: friendId === 0 ? responseData.userId : friendId,
        showResult: true,
        apiMessage: responseData,
      };
    } catch (error) {
      return {
        ...state,
        error: error.error,
      };
    }
  }

  function changeFriend(event) {
    setState({...state, isLoading: true});
    getState(event.target.value, state.apiMessage.page, state.apiMessage.pageSize).then(stateData => {
      setState({...stateData, isLoading: false})
    })
  }

  function changePage(pageNumber) {
    setState({...state, isLoading: true});
    getState(state.friendId, pageNumber, state.apiMessage.pageSize).then(stateData => {
      setState({...stateData, isLoading: false})
    })
  }

  function changePageSize(pageSize) {
    setState({...state, isLoading: true});
    getState(state.friendId, state.apiMessage.page, pageSize).then(stateData => {
      setState({...stateData, isLoading: false})
    })
  }

  return (

      <div className="console">
        <ContentHeader label={'Courses'}/>
        {!state.showResult ?
            <ConsoleLoading/> :
            <div>
              <div className="console">
                <div className={"detailconsolecontent"}>

                  <label style={{float: 'left', paddingRight: '1em'}}>
                    Friends
                  </label>
                  <FriendDrop selected={state.friendId} excludeNoRounders={false} excludePending={true} includeMe={true}
                              friendFunction={changeFriend}/>
                </div>
                <div className={"detailconsolecontent"}>
                  {(state.friendId !== state.apiMessage.userId && state.apiMessage.accessLevel !== "WRITE") ?
                      <>
                        You do not have update permissions for this account. Please ask your friend to grant you
                        update permissions if you wish to update their details.
                      </> :
                      <button className={"stdButton"} onClick={() => navigate("/new-course/" + state.friendId)}>Add a
                        new course</button>

                  }
                </div>

              </div>
              <div className="console scrollable-table-container">
                {state.isLoading ? (
                  <ConsoleLoading />
                ) : (
                  <>
                    {width > 1024 ?
                        <CoursesTableDesktop state={state} callbackfn={courseWithDetails =>
                            <CourseRowDesktop key={courseWithDetails.course.courseId} courseWithDetails={courseWithDetails}
                                              courseOwnerId={state.apiMessage.userId}
                                              accessLevel={state.apiMessage.accessLevel}/>}/>
                        :
                        <>
                          <CoursesTableMobile state={state} callbackfn={courseWithDetails =>
                              <CourseRowMobile key={courseWithDetails.course.courseId} courseWithDetails={courseWithDetails}
                                               courseOwnerId={state.apiMessage.userId}
                                               accessLevel={state.apiMessage.accessLevel}/>}/>
                        </>
                    }
                  </>
                )}
                <Pagination
                    apiMessage={state.apiMessage}
                    pageChangeFunction={changePage}
                    pageSizeFunction={changePageSize}
                    isLoading={state.isLoading}
                />
              </div>
            </div>
        }

      </div>
  );
};

export default withAuthenticationRequired(MyCoursesComponent, {
  onRedirecting: () => <Loading />,
});
