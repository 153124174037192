import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {getConfig} from "../../config";
import {renderLocalDateOrBlank} from "../../utils/Utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export const CourseRowDesktop = ({ courseWithDetails, courseOwnerId, accessLevel }) => {
    const navigate = useNavigate();
    const { apiOrigin = "http://localhost:8080" } = getConfig();

    // Extract course and details from the courseWithDetails object
    const { course, details } = courseWithDetails;

    const {
        getAccessTokenSilently,
    } = useAuth0();

    function denied(type) {
        toast.error("You don't have permission to " + type + " this course.")
    }

    async function deleteCourse(course) {
        const token = await getAccessTokenSilently();

        if (window.confirm("Are you sure you want to delete course " + course.courseName + "?")) {
            fetch(apiOrigin + '/courses/' + course.courseId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.status === 204) {
                        window.location.reload();
                    } else {
                        denied("delete")
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    function mustDeleteAllRounds() {
        toast.error("You must remove all rounds at this course before you can delete it");
    }

    function deleteDenied() {
        denied("delete");
    }

    return (
        <tr>
            <td nowrap="true" className="cell cellheader">
                <button className={"button_as_link"} style={{'textDecoration': 'none', color: '#FFFFFF'}}
                        onClick={() => navigate("/view-rounds/?friendId=" + courseOwnerId + "&courseId=" + course.courseId)}>
                    {course.courseName}</button>
            </td>
            <td className="cell" align="center">{course.courseTeesName}</td>
            <td className="cell celloldlight" align="center">{details.rounds}</td>
            <td className="cell celloldlight" align="center">{details.fullRounds}</td>
            <td className="cell celldate" align="center">{renderLocalDateOrBlank(details.lastRound)}</td>
            {details.underOverCount ? (
                <>
                    <td align="center" className="cell cellm4">{details.underOverCount[0]}</td>
                    <td align="center" className="cell cellalbatross">{details.underOverCount[1]}</td>
                    <td align="center" className="cell celleagle">{details.underOverCount[2]}</td>
                    <td align="center" className="cell cellbirdie">{details.underOverCount[3]}</td>
                    <td align="center" className="cell cellpar">{details.underOverCount[4]}</td>
                    <td align="center" className="cell cellbogey">{details.underOverCount[5]}</td>
                    <td align="center" className="cell celldoublebogey">{details.underOverCount[6]}</td>
                    <td align="center" className="cell cell3">{details.underOverCount[7]}</td>
                    <td align="center" className="cell cell4">{details.underOverCount[8]}</td>
                </>
                ) : (<td colSpan={9} align="center" className="cell">There was an error loading these details, please reload the page</td>)}
            <td align="center" className="cell celloldlight" bgcolor="#CCFFCC">3</td>

            <td align="center" className="cell cellicon">
                {accessLevel === "WRITE" && details.rounds === 0 ?
                    <button className={"button_as_link"} onClick={() => deleteCourse(course)}>Delete</button>
                    : (accessLevel === "READ" ?
                            <button className={"button_as_link"} onClick={deleteDenied}
                                    style={{opacity: 0.3}}>Delete</button>
                            : <button className={"button_as_link"} onClick={mustDeleteAllRounds}
                                      style={{opacity: 0.3}}>Delete</button>
                    )
                }
            </td>
            <td align="center" className="cell cellicon">
                {(accessLevel === "WRITE" &&
                        <button className={"button_as_link"}
                                onClick={() => navigate("/edit-course/" + course.courseId)}>Edit</button>) ||
                    <button className={"button_as_link"} onClick={() => denied("edit")}
                            style={{opacity: 0.3}}>Edit</button>
                }
            </td>
            <td align="center" className="cell cellicon">
                {(accessLevel === "WRITE" &&
                        <button className={"button_as_link"}
                                onClick={() => navigate("/new-round/" + course.courseId)}>Add Round</button>) ||
                    <button className={"button_as_link"} onClick={() => denied("add a round at")}
                            style={{opacity: 0.3}}>Add Round</button>
                }
            </td>
        </tr>
    )
}

export default CourseRowDesktop;
